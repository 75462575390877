import React from 'react'
import { Typography,Box,Grid,Paper, CardActionArea,TextField,Avatar} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
// import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import { padding } from '@mui/system';

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
// import 'bootstrap/dist/css/bootstrap.min.css';  

// import {Container ,Card, Col, Button} from 'react-bootstrap';  
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
// import AdUnitsIcon from '@mui/icons-material/AdUnits';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import BoltIcon from '@mui/icons-material/Bolt';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CampaignIcon from '@mui/icons-material/Campaign';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';

import logo from '../images/logo.png';
import jaidev from '../images/jaidev.jpg';
import mackenzie from '../images/mackenzie.jpg';
import ramon from '../images/ramon.jpg';
import sam from '../images/sam.jpeg';
import screen from '../images/screen.png';
import fintech from '../images/fintech-devices.jpeg';
import hospitality from '../images/hospitality.jpeg';
import consulting from '../images/consulting.jpeg';
import health from '../images/healthcare.jpeg';
import retail from '../images/retail.jpeg';
import education from '../images/education.jpeg';
import legal from '../images/legal.png';
import media from '../images/media.png';



const bannerStyle={  textAlign: "center",marginTop: '150px',fontWeight: 'bold',fontSize: 85,color: "#1D2951"}
const subTitle1={textAlign: "center",p:"55px",paddingLeft:"100px",paddingRight:"100px"}
const secondHead={ textAlign: "center",fontWeight: 'medium',fontSize: 40,fontWeight:'bold',color:'#1d2951'}
const subCard={margin:"auto",boxShadow:'0px 0px 7px 2px #000',
height: "10vw" ,
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
    title: "Bed"
  },
  {
    img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
    title: "Books"
  },
  {
    img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
    title: "Sink"
  },
  {
    img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
    title: "Kitchen"
  },
  {
    img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
    title: "Blinds"
  },
  {
    img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
    title: "Chairs"
  },
  {
    img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
    title: "Laptop"
  },
  {
    img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
    title: "Doors"
  },
  {
    img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
    title: "Coffee"
  },
  {
    img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
    title: "Storage"
  },
  {
    img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
    title: "Candle"
  },
  {
    img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
    title: "Coffee table"
  }
];


function banner() {
  return (
    

    <Box >
      
      <Typography  style={bannerStyle} >Innovate your business with technology</Typography>

      <Grid container 
       spacing={{ xs: 2, md: 3}} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ p:"55px",paddingLeft:"100px",paddingRight:"100px", display:"flex",flexDirection:"column",alignItems:"center"}}
          >
        <div style={{display:"flex",gap:"20px" ,marginTop:"10px" ,marginBottom:"20px",width:"fit-content"}}>
        <Item>Web developement</Item>
        <Item>Software developement</Item>
        <Item>Mobile App developement</Item>
           
        </div>
        <div style={{display:"flex",gap:"20px",width:"fit-content"}}>
        <Item>Data&API integration </Item>
        <Item>Intelligence&analytics </Item>
        <Item>Quality Assurance</Item>

        </div>
          {/* <Grid item xs={2} sm={4} md={4}  > */}
           
            
          {/* </Grid> */}
          {/* <Grid item xs={2} sm={4} md={4} > */}
            
          {/* </Grid> */}
          {/* <Grid item xs={2} sm={4} md={4} > */}
           
           
          {/* </Grid> */}

          {/* <Grid item xs={2} sm={4} md={4} > */}
         
           
          {/* </Grid> */}

          {/* <Grid item xs={2} sm={4} md={4} > */}
           
          {/* </Grid> */}

          {/* <Grid item xs={2} sm={4} md={4} > */}
           
          {/* </Grid> */}


      </Grid>


<div style={{marginTop:12,p:"55px",paddingLeft:"100px",paddingRight:"100px" ,backgroundColor:"#292f36"}}>
      <Typography style={{ paddingTop:"12px",   textAlign: "center",fontWeight: 'medium',fontSize: 40,fontWeight:'bold', color: "#7A82AB"}}>Get results</Typography>
     <Grid container spacing={2} sx={{p:"55px",paddingLeft:"100px",paddingRight:"100px" ,backgroundColor:"#292f36"}}>
      <Grid item xs={3.5}
       sx={{ margin:" auto" }}>
<Card  sx={{maxWidth:345}} style={subCard}  >
  <CardActionArea>
    <CardContent>
    <div style={{display:"flex", alignItems:"center",color:" #7A82AB",gap:"20px"}}>
      <BoltIcon/>
      <Typography gutterBottom variant="h5" color="#fff" component="div">
        Speed
      </Typography>
      </div>
      <Typography variant="body2" color="#fff">We understand that time is of the essence, and we’ll work tirelessly to ensure that your project is completed on time and on budget.

      </Typography>
    </CardContent>
  </CardActionArea>
</Card>
      </Grid>
      <Grid item xs={3.5}
      
       sx={{ margin:" auto"}}>
<Card sx={{maxWidth:345}} style={subCard}>
  <CardActionArea>
    <CardContent>
      <div style={{display:"flex", alignItems:"center",color:" #7A82AB",gap:"20px"}}>
        <WorkspacePremiumIcon/>
      <Typography gutterBottom color="#fff" variant="h5" component="div">
        Quality
      </Typography>
      </div>
      <Typography variant="body2" color="#fff">While speed is important, we take great care to ensure that every project meets the highest standards of quality.

      </Typography>
    </CardContent>
  </CardActionArea>
</Card>
      </Grid>

      <Grid item xs={3.5}
       sx={{ margin:" auto"}}>
<Card sx={{maxWidth:345}} style={subCard}>
  <CardActionArea>
    <CardContent>
      <div style={{display:"flex", alignItems:"center",color:" #7A82AB",gap:"20px"}}>
        <QuestionAnswerIcon/>
      <Typography gutterBottom color="#fff" variant="h5" component="div">
        Support
      </Typography>
      </div>
      <Typography variant="body2" color="#fff">We’re dedicated to providing exceptional customer service and making sure that your experience with us is a positive one.

      </Typography>
    </CardContent>
  </CardActionArea>
</Card>
      </Grid>

     </Grid>
</div>
     <Typography style={secondHead}> Produce quality experiences</Typography>


     <Grid container spacing={2} sx={{p:"55px",paddingLeft:"100px",paddingRight:"100px" }}>
      <Grid item xs={3.5}
       sx={{ margin:" auto" }}>
<Card  sx={{maxWidth:345}} style={subCard}  >
  <CardActionArea>
    <CardContent>
      <div style={{display:"flex", alignItems:"center",color:"#1D2951",gap:"20px"}}>
< ImageAspectRatioIcon />
      <Typography gutterBottom variant="h5" component="div" >
        Design
      </Typography>
      </div>
      <Typography variant="body2" color="text.secondary">We understand that time is of the essence, and we’ll work tirelessly to ensure that your project is completed on time and on budget.

      </Typography>
    </CardContent>
  </CardActionArea>
</Card>
      </Grid>



     

      <Grid item xs={3.5}
      
       sx={{ margin:" auto"}}>
<Card sx={{maxWidth:345}} style={subCard}>
  <CardActionArea>
    <CardContent>
      <div  style={{display:"flex", alignItems:"center",color:"#1D2951",gap:"20px"}}>
        <CodeOffIcon/>
      <Typography gutterBottom variant="h5" component="div">
        Develop
      </Typography>
      </div>
      <Typography variant="body2" color="text.secondary">While speed is important, we take great care to ensure that every project meets the highest standards of quality.



      </Typography>
    </CardContent>
  </CardActionArea>
</Card>
      </Grid>

      <Grid item xs={3.5}
       sx={{ margin:" auto"}}>
<Card sx={{maxWidth:345}} style={subCard}>
  <CardActionArea>
    <CardContent>
      <div  style={{display:"flex", alignItems:"center",color:"#1D2951",gap:"20px"}}>
        < CampaignIcon/>
      <Typography gutterBottom variant="h5" component="div">
        Market
      </Typography>
      </div>
      <Typography variant="body2" color="text.secondary">We’re dedicated to providing exceptional customer service and making sure that your experience with us is a positive one.

      </Typography>
    </CardContent>
  </CardActionArea>
</Card>
      </Grid>

     </Grid>


<div   >
<Typography      sx={{position:"absolute",textAlign: "center",fontWeight: 'medium',fontSize: 40,fontWeight:'bold',paddingLeft:"333px",color: "#7A82AB",paddingBottom:"15px"}}    
 >Invest in your digital ecosystem
</Typography>
      <Box
      component="ul"
      sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' ,p:"55px",paddingLeft:"160px",backgroundColor:"#292f36",paddingTop:"100px"}}
    >
      <Card component="li" sx={{ minWidth: 300 }}>
        <CardCover>
          <img
           src={screen}
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            // fontWeight="lg"
            // textColor="#fff"
            textAlign= 'center'
            fontWeight= 'bold'
            color="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Real estate
          </Typography>
        </CardContent>
      </Card>

      <Card component="li" sx={{ minWidth: 300 }}>
        <CardCover>
          <img
          
            src={fintech}
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            // fontWeight="lg"
            // textColor="#fff"
            textAlign= 'center'
            fontWeight= 'bold'
            color="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            FinTech
          </Typography>
        </CardContent>
      </Card>

      <Card component="li" sx={{ minWidth: 300}}>
        <CardCover>
          <img
            src={hospitality}
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            // fontWeight="lg"
            // textColor="#fff"
            textAlign= 'center'
            fontWeight= 'bold'
            color="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Hospitality
          </Typography>
        </CardContent>
      </Card>

      <Card component="li" sx={{ minWidth: 300}}>
        <CardCover>
          <img
            src={consulting}
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            // fontWeight="lg"
            // textColor="#fff"
            textAlign= 'center'
            fontWeight= 'bold'
            color="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Consulting
          </Typography>
        </CardContent>
      </Card>
      <Card component="li" sx={{ minWidth: 300 }}>
        <CardCover>
          <img
            src=
            {health}
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            // fontWeight="lg"
            // textColor="#fff"
            textAlign= 'center'
            fontWeight= 'bold'
            color="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Healthcare
          </Typography>
        </CardContent>
      </Card>
      <Card component="li" sx={{ minWidth: 300, }}>
        <CardCover>
          <img
            src={retail}
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            // fontWeight="lg"
            // textColor="#fff"
            textAlign= 'center'
            fontWeight= 'bold'
            color="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Retail

          </Typography>
        </CardContent>
      </Card>
      <Card component="li" sx={{ minWidth: 300}}>
        <CardCover>
          <img
            src=
            {education}
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            // fontWeight="lg"
            // textColor="#fff"
            textAlign= 'center'
            fontWeight= 'bold'
            color="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Education
          </Typography>
        </CardContent>
      </Card>
      <Card component="li" sx={{ minWidth: 300}}>
        <CardCover>
          <img
            src={legal}
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            // fontWeight="lg"
            // textColor="#fff"
            textAlign= 'center'
            fontWeight= 'bold'
            color="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Legal
          </Typography>
        </CardContent>
      </Card>
      <Card component="li" sx={{ minWidth: 300}}>
        <CardCover>
          <img
            src={media}
          />
        </CardCover>
        <CardContent>
          <Typography
            level="h6"
            // fontWeight="lg"
            // textColor="#fff"
            textAlign= 'center'
            fontWeight= 'bold'
            color="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Media
          </Typography>
        </CardContent>
      </Card>

      

    
    </Box>


    </div>  



<div container spacing={5} columns={16} style={{paddingLeft:"100px",paddingRight:"100px",display:"flex" }}>
        

      <div style={{display:"flex", flexDirection:"column", alignItems:"center",  minWidth:"50%"}} >
<div>
      <Typography color="#1d2951"  textAlign= 'center'
            fontWeight= 'bold' fontSize='50px'>Meet the team</Typography>
      <Typography color='#7a82ab'  textAlign= 'center'
            fontWeight= 'bold' fontSize='40px'>Contact us</Typography>
      </div>
      <Grid item xs={8} sx={{ display: "flex", flexWrap: "wrap", gap: 2}}>
       
      <Container component="main" maxWidth="xs">
        {/* <CssBaseline /> */}
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
            
          
        
          <Box  component="form" noValidate  sx={{ mt: 3 ,display:"flex",flexDirection:"column"}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus={false}
                 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="text"
                  label="Company"
                  name="company"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  // name="password"
                  label="Add Notes"
                  // type="password"
                  id="text"
                  
                />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              // fullWidth
               variant="contained"
              //  color='#7a82ab'
              sx={{ mt: 3, mb: 2,alignSelf:"flex-end", backgroundColor:'#7a82ab' }}
            >
              Submit
            </Button>
           
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
        
        </Grid>
      </div>
      
      <div item xs={8} style={{ display: "flex",flexDirection:"column", alignItems:"center" ,width:"50%" }}>
       
       <Grid  item xs={8} sx={{ display: "flex", flexWrap: "wrap", gap: 2, width:"100%"}}>

<div style={{width:"45%" ,display:"flex", flexDirection:"column"}}>
        <img  style={{
             width: "100%",
            height: "auto",
            objectFit: "contain",
            borderRadius:"11px"
          }} src={jaidev}/> 
          <div> <h1>Jaidev Jyotis</h1>
          <p>Managing Director
          </p>
          </div>
</div>
<div  style={{width:"45%"}}>
      <img  style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            borderRadius:"11px"

          }} src={mackenzie}/> 
               <div> 
                <h1 >MackenzieTaylor</h1>
                      <p >Marketing Lead</p>
          </div>
</div>
<div  
style={{width:"45%"}}>
      <img  style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            borderRadius:"11px"

          }} src={ramon}/> 
          <h1>Ramon Crayton</h1>
          <p>Technology Lead</p>
</div>
<div  style={{width:"45%"}}>
<img  style={{
          width: "100%",
            height: "auto",
            objectFit: "contain",
            borderRadius:"11px"

          }} src={sam}/> 
             <h1>Samuel Martin</h1>
            <p>Managing Partner</p>
</div>
        </Grid>
      </div>
    </div>
  

    




    








<footer>
<Grid container spacing={100} sx={{ paddingLeft:"100px",paddingRight:"100px" ,paddingTop:"100px"
              
            }} >
  <Grid item xs={12} sm={6} >
    <div  style={{display:"flex", alignItems:"center",gap:"5px"}}>
    <Avatar  src={logo}  sx={{ height:35, width:35 }}></Avatar>

                <p>
                ©[moderndope]LLC2023
                </p>
                </div>      
              
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{display:"flex", alignItems:"center",gap:"10px"}}>
                
                  < PersonPinCircleIcon/>           
                   440 S La Salle St, Chicago, IL 60605
                
                </div>
                <div  style={{display:"flex", alignItems:"center",gap:"10px"}}>
                
                  <MarkunreadIcon/>
                info@moderndope.com

                
                </div>
                <div style={{display:"flex", alignItems:"center",gap:"20px"}}>

                <LocalPhoneIcon/>
                ‪(773) 823-4319‬
                
                </div>
              </Grid>
  </Grid>
</footer>


    </Box>
    
    

    
  )
}



 

export default banner
