import React from 'react'
import {AppBar, Toolbar, Typography,Button, Avatar} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
//  import logo from '/images/logo.png';
 import logo from '../images/logo.png';
 import MarkunreadIcon from '@mui/icons-material/Markunread';
 import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

const Header = () => {
    

  return (
    <div>
      <React.Fragment>
        <AppBar sx={{background:"#ffff", p:"15px",paddingLeft:"75px",paddingRight:"75px"}}>
            <Toolbar >
           
            {/* <Button   sx={{backgroundColor: " #E1E1E1",textColor:"#292F36BD",padding: "18px 18px 18px 18px"}} startIcon={<SearchIcon />}>
              <span sx={{textColor:" black"}}> Explore</span>
           
            </Button> */}
            {/* <img  component="img"  sx={{ height:1  }} alt="Logo" src={logo}/> */}
         
            <Avatar  src={logo}  sx={{ height:75, width:75 }}></Avatar>
            
            {/* <Avatar src="/images/logo.png" sx={{ marginLeft: "auto" }} /> */}
            <Button sx={{ marginLeft: "auto" ,backgroundColor: "#7A82AB",padding: "18px 18px 18px 18px"}}endIcon={  <MarkunreadIcon/>}  variant="contained">
                Contact 
              </Button>
            <Button sx={{ backgroundColor: "#E1E1E1",color:"Black",marginLeft: "10px",padding: "18px 18px 18px 18px" }} endIcon={< ShoppingBagIcon />} >
            <span sx={{textColor:"black"}}> Merch</span> 
              </Button>
            
            </Toolbar>
            
        </AppBar>
      </React.Fragment>

    </div>
  )
}

export default Header
